<template>
  <div class="background">
    <div class="row m-0 p-0">
      <div
        v-if="!redirecting"
        class="col-md-6 col-12 p-0 m-0"
      >
        <div
          class="info-login d-flex flex-column justify-content-center align-items-center"
        >
          <div class="title-container">
            <h1 class="title-login">Inicia sesión y vive<br>la realidad virtual<br>SIN PUBLICIDAD</h1>
          </div>
          <img
            src="https://storage.googleapis.com/planeta-guru-assets-bucket/images/login/hedset.png"
            class="hedset-mobile">
          <div
            v-if="!redirecting"
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <b-alert
              :show="error !== null"
              variant="danger"
            >
              {{ error }}
            </b-alert>
            <label
              for="input-login"
              class="input-login-label">Ingresá tu número celular</label>
            <VuePhoneNumberInput
              v-model="ani"
              :only-countries="inputConfigs.countries"
              :default-country-code="inputConfigs.defaultCountry"
              :translations="inputConfigs.translations"
              :size="inputConfigs.size"
              required
              dark
              @update="setAni"
            />
            <button
              :disabled="loading || disabled"
              class="button-login mt-2"
              @click="login()"
            >
              <template v-if="loading">
                <b-spinner
                  small
                  type="grow"
                  class="my-auto p-3"
                />
                Cargando...
              </template>

              <template v-else>
                Ingresar
              </template>
            </button>
          </div>
          <div
            v-else
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <b-spinner
              small
              type="grow"
              class="my-auto p-3"
            />
          </div>
          <span class="description-container text-center text-white mt-2">¿Primera vez en Claro VR?
            <a
              :href="subscriptionUrl"
              class="text-white font-weight-bold"><u>Suscríbete ahora</u></a>
            <p class="mt-3">
              Con tu suscripción tendrás acceso a juegos y videos de realidad virtual, aumentada y 360° de manera
              ilimitada.
            </p>
          </span>
        </div>
      </div>
      <div
        v-else
        class="col-md-6 col-12 p-0 m-0"
      >
        <div class="info-login d-flex flex-column justify-content-center align-items-center w-100 h-auto d-inline-block">
          <div class="title-container mb-4">
            <h1 class="title-login">Inicia sesión y vive<br>la realidad virtual<br>SIN PUBLICIDAD</h1>
          </div>
          <b-spinner
            class="mx-auto  p-3"
            variant="light"
            style="width:8rem; height: 8rem;"
          />
        </div>
      </div>
      <div class="col-md-6 col-12 p-0 m-0">
        <div class="image-login">
          <img
            src="https://storage.googleapis.com/planeta-guru-assets-bucket/images/login/hedset.png"
            class="hedset">
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { BAlert, BButton, BSpinner } from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
export default {
  components: {
    BAlert,
    BButton,
    BSpinner,
    VuePhoneNumberInput
  },
  data() {
    return {
      buttonTitle: 'Iniciar sesión',
      showInput: false,
      ani: null,
      formatedMsisdn: null,
      error: null,
      loading: false,
      redirecting: false,
      countryShortName: '',
      disabled: true,
      inputConfigs: {
        size: 'lg',
        countries: ['AR', 'CO', 'EC', 'MX', 'PE', 'UY', 'DO', 'PR'],
        defaultCountry: 'AR',
        translations: {
          countrySelectorLabel: 'Codigo de pais',
          countrySelectorError: 'Seleccione un codigo de pais',
          phoneNumberLabel: 'Numero',
          example: 'Ejemplo :'
        }
      }
    }
  },
  computed: {
    subscriptionUrl() {
      return this.$store.state.settings.subscribeUrl
    }
  },
  mounted() {
    this.$store.state.profile !== null && this.$router.push('/')
  },
  methods: {
    startLogin() {
      this.showInput = true
      this.buttonTitle = 'Ingresar'
    },
    setError(error) {
      this.error = error
    },
    clearError() {
      this.error = null
    },

    setAni(payload) {
      if (payload && payload.formattedNumber) {
        let msisdn = payload.formattedNumber
          .replace(/\s/g, '')
          .replace(/\+/g, '')

        if (msisdn.startsWith('5411')) {
          msisdn = msisdn.slice(0, 2) + '9' + msisdn.slice(2)
          this.ani = msisdn
        }
        this.formatedMsisdn = msisdn
        if (payload.isValid) {
          this.ani = msisdn
        }
      }

      this.disabled = !payload.isValid
    },

    async login() {
      this.clearError()
      this.loading = true
      const response = await this.$store.dispatch(
        'backend/userLogin',
        this.formatedMsisdn
      )
      if (response) {
        if (response.status === 200) {
          const data = response.data
          if (data.error) {
            this.setError(data.message)
          }

          if (!data.error) {
            this.clearError()
            console.log('data', data)
            const url = data.data.urlLogin ? data.data.urlLogin : null
            if (url == null) {
              this.setError('Error al iniciar sesión')
            }

            if (url !== null) {
              this.redirecting = true
              window.location.href = url
            }
          }
        }

        if (response.status === 422) {
          this.setError(response.data.message)
        }

        if (response.status === 500) {
          this.setError('Error interno del servidor')
        }
      }
      this.loading = false
    }
  }
}
</script>
